/**-------------------------------------------------------------
1.2.4.1 EV쿠폰 메인화면_3 - 쿠폰미등록
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/coupon.html
-------------------------------------------------------------*/

/* eslint-disable */


// 기본
import { useState, useEffect, useCallback, ChangeEvent, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Sheet from "react-modal-sheet";

//헤더 
import { Header } from '../../components/Layout';
import { globalVars } from '../../libs/global-vars';
import { HandleBrowserBackButton } from '../../components/Layout'

import Const from '../../store/ev'

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getCouponKey, patchCouponActive, postCoupon } from '../../apis/coupon';
import { getUser, postUser } from '../../apis/user';
import { modal } from '../../components/GlobalModal';

import AES from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";
import encBase64 from "crypto-js/enc-base64";
import padPkcs7 from "crypto-js/pad-pkcs7";
import ECB from "crypto-js/mode-ecb"; // ECB 모드 추가
import { patchAgree } from '../../apis/agreement';
import { store } from '../../store';
import { tmapInterface } from '../../libs/tmap-interface';


let scrollCache = 0;
let cvcAgreeCheck;
let userData: any = {};    // 회원정보조회(단건)

function CouponRegister(props: RouteComponentProps<{ id: string, productType: string }>) {
    const [couponList, setCouponList] = useState([{ couponCode: "" }]);
    const [isDisabled, setIsDisabled] = useState(true); // 선택한 쿠폰 적용하기 버튼
    const [inited] = useState(false);
    const [cvcSheet, setCvcSheet] = useState(false);
    const [sendCouponCode, setSendCouponCode] = useState(""); //쿠폰 코드 값
    const [agreeCheck, setAgreeCheck] = useState(false);
    const [btnFocus, setBtnFocus] = useState(false);
    const [sendCvc, setSendCvc] = useState('');
    const [cvcInputDisable, setCvcInputDisable] = useState(true);
    const [postReqStatus, setPostReqStatus] = useState(false);
    const [firstClick, setFirstClick] = useState(false);
    const [isPC, setIsPC] = useState(false);

    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);
    const [couponType, setCouponType] = useState(0);

    cvcAgreeCheck = store.getState().app.cvcAgree;

    const onChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target;
        const list = [...couponList];
        list[index]["couponCode"] = value.replace(/-/,'');
        if (value.length > 0) {
            setIsDisabled(false);
            setSendCouponCode(value);
        } else {
            setIsDisabled(true);
        }
        setCouponList(list);
    };

    const onChangeCvc = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        
        if (value.length > 0) {
            setCvcInputDisable(false);
            setSendCvc(value);
        } else {
            setCvcInputDisable(true);
        }
    };

    useEffect(() => {
        mixpanel("evcharge:/evcharge/addcoupon");
        logbox("/evcharge/addcoupon", "");
    }, [])

    const couponPost = (cpoCode?: string) => {
        if (firstClick) return;

        if (cpoCode === "PC") {
            setIsPC(true);
        }

        setIsDisabled(true);
        setPostReqStatus(true);
        
        var data = {
            productCode: sendCouponCode,
            encryptedCardPassword: cpoCode === "PC" ? encryptData(sendCvc) : null
        };

        postCoupon(data).then(function (response: any) {
            setSendCouponCode("");
            setSendCvc("");
            setCvcSheet(false);
            // if (cpoCode === "PC") {
                
            // } else {
                if (response.result) { // 정상적으로 쿠폰 추가
                    mixpanel("evcharge:/evcharge/addcoupon:view.successcoupon_popup");
                    logbox("/evcharge/addcoupon", "tap.successcoupon_popup");

                    modal.alert(
                        () => {
                            setCouponType(response.couponType);
                        },
                        '쿠폰이 등록되었습니다.',
                    );
                } else {
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");
                    
                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                }
            // }
        })
        .catch(function (error: any) {
            setSendCouponCode("");
            setSendCvc("");
            setCvcSheet(false);
            setFirstClick(false);
            switch (error?.response?.data?.reason) {
                case "EV-PRODUCT-VALIDATION-FAIL-001":
                    mixpanel("evcharge:/evcharge/addcoupon:view.registcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.registcoupon_popup");

                    modal.alert(
                        () => registPop("regist"),
                        '이미 등록된 쿠폰입니다.',
                    );
                    break;
                case "EV-PRODUCT-DATE-INVALID-001":
                    mixpanel("evcharge:/evcharge/addcoupon:view.dateendcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.dateendcoupon_popup");

                    modal.alert(
                        () => registPop("end"),
                        '등록 유효기간이 만료된 쿠폰입니다.',
                    );
                    break;
                case "EV-PRODUCT-VALIDATION-FAIL-002":
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                    break;
                case "EV-ESS-REQ-FAIL-001":
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                    break;
                case "EV-PRODUCT-11ST-FAIL-002":
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                    break;
                case "EV-PRODUCT-11ST-FAIL-001":
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                    break;
                default:
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                    break;
            }
        });
    }

    const openPCApp = () => {
        const fallbackTimeout = setTimeout(() => {
            tmapInterface.openBrowser(globalVars.isIOS? "https://apps.apple.com/kr/app/id978597106":"https://play.google.com/store/apps/details?id=kr.co.iparking.android")
        }, 1500); // 1.5초 내에 앱이 열리지 않으면 스토어로 이동
        
        tmapInterface.openBrowser("pacl://evcharge?callerScheme=tmap");
        
        window.addEventListener('pagehide', () => {
            clearTimeout(fallbackTimeout); // 앱이 정상적으로 열리면 타이머 취소
        });
    }


    // couponList로 인해 젠킨스 빌드 시 오류 발생. 확인 필요
    // 쿠폰등록 팝업
    const registerCoupon = () => {
        mixpanel("evcharge:/evcharge/addcoupon:tap.add");
        logbox("/evcharge/addcoupon", "tap.add");

        setIsDisabled(true);

        getCouponKey(sendCouponCode).then((res:any) => {
            if (res.result && res.productKey === "PC-POINT-001") {
                setCvcSheet(true); //cvc 입력창 open
            } else {
                couponPost(); //일반 쿠폰 등록씬
            }
        }).catch((err) => {
            console.log(err)
        })
    };

    useEffect(() => {
        if (couponType !== 0) {
            registPop("success");
        }
    },[couponType])

    const registPop = (status: any) => {
        switch (status) {
            case "success":
                mixpanel("evcharge:/evcharge/addcoupon:tap.successcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.successcoupon_ok");
                break;
            case "regist":
                mixpanel("evcharge:/evcharge/addcoupon:tap.registcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.registcoupon_ok");
                break;
            case "end":
                mixpanel("evcharge:/evcharge/addcoupon:tap.dateendcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.dateendcoupon_ok");
                break;
            default:
                mixpanel("evcharge:/evcharge/addcoupon:tap.wrongcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.wrongcoupon_ok");
        }

        if (globalVars.queryData.extra?.servicetype === 'couponRegister' || globalVars.queryData.extra?.serviceType === 'couponRegister' || globalVars.queryData.extra?.servicetype === 'couponregister' || globalVars.queryData.extra?.serviceType === 'couponregister') {
            globalVars.queryData.extra.servicetype = '';
            globalVars.queryData.extra.serviceType = '';
            globalVars.queryData.extra.servicetype = '';
            globalVars.queryData.extra.serviceType = '';
        }

        setCouponList([{ couponCode: "" }]);
        
        if (status === "success") {
            if (isPC) {
                openPCApp();
            } else {
                if (couponType === 1) {
                    goPointCoupon();
                } else {
                    goRateCoupon();
                }
            }
        }
    };

    const goPointCoupon = () => {
        props.history.replace('/pointCoupon');
    }
    const goRateCoupon = () => {
        props.history.replace('/rateCoupon');
    }

    useEffect(() => {
        window.scrollTo(0, scrollCache);
        const serviceType = globalVars.queryData.extra?.servicetype?.toLowerCase() || globalVars.queryData.extra?.serviceType?.toLowerCase();

        const cardNumber = globalVars.queryData.extra?.payLoad?.cardNumber || globalVars.queryData.extra?.payload?.cardNumber;
        const activation = globalVars.queryData.extra?.payLoad?.activation || globalVars.queryData.extra?.payload?.activation;

        if (serviceType === 'couponregister') {
            if (cardNumber) {
                setSendCouponCode(cardNumber as string);
                setIsDisabled(true);
                // if (activation === 'y') { //activation true면
                patchCouponActive({ productCode: cardNumber, activeYn: activation === 'y' ? true : false }).then((res: any) => {
                    console.log(res);
                }).catch((err) => {
                    console.log(err);
                });
                // }
            } else {
                setSendCouponCode((globalVars.queryData.extra?.payLoad || globalVars.queryData.extra?.payload) as string)
            }
            if (globalVars.accessKey) {

                if (Const.DBG_OK) {
                    console.log("#2 AccessKey 검증 및 이상없으면 회원정보 리턴");
                }

                getUser()
                    .then(function (response: any) {
                        // 기존회원이므로 회원정보 받기
                        if (response.result) {
                            userData = response;

                            if (Const.DBG_OK) {
                                // console.log(JSON.stringify(response.data));
                                alert("사용자정보 " + JSON.stringify(userData));
                            }

                            // 미등록회원이므로 DB에 등록
                        }
                    })
                    .catch(function (error: any) {
                        if (error.response.status === 400 || error.response.data.result === false) {
                            // 신규회원등록
                            var data = {};

                            postUser(data)
                                .then(function (response: any) {
                                    if (Const.DBG_OK) {
                                        // console.log('신규회원등록')
                                        alert("신규회원등록 " + JSON.stringify(response.data));
                                    }

                                    getUser()
                                        .then(function (response: any) {
                                            userData = response;

                                            if (Const.DBG_OK) {
                                                // console.log('신규회원정보 조회성공')
                                                alert("신규회원 " + JSON.stringify(response.data));
                                            }
                                        })
                                        .catch(function (error: any) {
                                            //							alert('신규회원정보 조회실패')
                                            console.log(error);
                                        });
                                })
                                .catch(function (error: any) {
                                    console.log(error);
                                });
                        }
                    });
            } else {
                if (Const.DBG_OK) {
                    alert("ACCESS KEY 없는경우 - 접속 제한 ()");
                    //props.history.replace("/notFound");
                }
                props.history.replace("/notFound");
            }
        }
    }, [inited]);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);

    const resPayLoad = () => {
        const { payLoad, payload } = globalVars.queryData.extra || {} ;

        if (payLoad !== undefined && payLoad as string !== "") {
            if (payLoad?.cardNumber) {
                return payLoad.cardNumber as string;
            } else {
                return payLoad;
            }
        }

        if (payload !== undefined && payload as string !== "") {
            if (payload?.cardNumber) {
                return payload.cardNumber as string;
            } else {
                return payload;
            }
        }
    };

    const enterRegist = (e) => {
        if (e.key == 'Enter' || e.keyCode == 13) {
            e.preventDefault();
            e.target.blur();
            // return false;
        }
    };

    const onChangeCheck = () => {
        if (agreeCheck === false) {
            setAgreeCheck(true);
        } else {
            setAgreeCheck(false);
        }
    }
    
    const encryptData = (plainText: string): string => {
        const key = encUtf8.parse(process.env.REACT_APP_AES_KEY as string);

        const encrypted = AES.encrypt(plainText, key, {
            mode: ECB,
            padding: padPkcs7,
        });

        // Base64 형식으로 변환
        const base64Encrypted = encBase64.stringify(encrypted.ciphertext);

        return base64Encrypted;
    };
    
    const handleRegisterCard = () => {
        setFirstClick(true);

        const agreebody = {
            "agreementType": 2,
            "agreementCode": 1,
            "agreementYn": true
        }

        if (!cvcAgreeCheck && !postReqStatus) {
            patchAgree(agreebody).then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err)
            })
        }

        couponPost("PC"); //pc쿠폰 등록
    }

    const inputRef = useRef<HTMLInputElement>(null);

    const handleBackdropClick = () => {
        inputRef.current?.blur(); // 입력 필드의 포커스를 해제

        if (globalVars.isAndroid) {
            setTimeout(() => {
                setCvcSheet(false)
            }, 200)
        } else {
            setCvcSheet(false)
        }
        setIsDisabled(false);
    };

    return (
        <>
            <Header title={'EV 쿠폰 등록'} isMenu={false} isActive={'3'} />
            {<HandleBrowserBackButton />}

            <div className="coupon-register">
                <div className='coupon-regist-title'>쿠폰 등록</div>
                <div className="register-box">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <fieldset style={{ display: "flex"}}>
                            <div className="field">
                                {couponList.map((x, i) => {
                                    return (
                                        <>
                                            <input
                                                key={i}
                                                type="text"
                                                name="couponCode"
                                                placeholder="쿠폰 코드를 입력해주세요."
                                                value={(globalVars.queryData.extra?.servicetype === 'couponRegister' || globalVars.queryData.extra?.serviceType === 'couponRegister' || globalVars.queryData.extra?.servicetype === 'couponregister' || globalVars.queryData.extra?.serviceType === 'couponregister') ? resPayLoad() as string : x.couponCode}
                                                onChange={e => onChange(e, i)}
                                                onKeyPress={enterRegist}
                                            />
                                        </>
                                    )
                                })}
                            </div>
                            <div className="coupon-add-btn" style={{zIndex:"9"}}>
                                <button type="button" onClick={registerCoupon} disabled={(globalVars.queryData.extra?.servicetype === 'couponRegister' || globalVars.queryData.extra?.serviceType === 'couponRegister' || globalVars.queryData.extra?.servicetype === 'couponregister' || globalVars.queryData.extra?.serviceType === 'couponregister') ? false : isDisabled}>등록</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <Sheet
                    isOpen={cvcSheet}
                    onClose={() => handleBackdropClick()}
                    snapPoints={[350,0]}
                    disableDrag={true}
                >
                    <Sheet.Container style={{ borderTopLeftRadius:"16px", borderTopRightRadius:"16px", overflow:"hidden"}}>
                        <Sheet.Content>
                            <div style={{textAlign:"center", letterSpacing:"normal", paddingTop:"50px"}} className='cvc-box'>
                                <div style={{fontSize:"20px", fontFamily:"TMOBI500", marginBottom:"10px"}}>CVC 번호 입력</div>
                                <div style={{fontSize:"16px", fontFamily:"TMOBI300", color:"#8C9299"}}>CVC 번호를 입력해주세요</div>
                                <input
                                    type="number"
                                    name="cvcNum"
                                    onChange={e => onChangeCvc(e)}
                                    style={{
                                        borderBottom: "2px solid #111", fontFamily: "TMOBI500", fontSize: "24px", marginTop: "20px", textAlign: "center", outline: "none", width: "70%", letterSpacing: "normal", padding: "0 0 10px 5px"}}
                                />
                                <p className="check" style={{marginTop:"40px", textAlign:"left", padding:"0 30px", position:"fixed"}}>
                                    <input
                                    type="checkbox"
                                    id="chk2"
                                    checked={cvcAgreeCheck || agreeCheck}
                                    onChange={onChangeCheck}
                                    />
                                    <label htmlFor="chk2" style={{fontFamily:"TMOBI300", fontSize:"14px"}}>(필수) 개인정보 제 3자 제공 동의</label>
                                </p>
                                <div className={((agreeCheck || cvcAgreeCheck) && !cvcInputDisable) ? 'footer-button' : 'dim-footer-button'} onClick={() => { ((agreeCheck || cvcAgreeCheck) && !cvcInputDisable) ? handleRegisterCard():""}}>
                                    <a style={btnFocus ? { filter: "brightness(90%)" } : {}} onTouchStart={() => {((agreeCheck || cvcAgreeCheck) && !cvcInputDisable) ? setBtnFocus(true):""}} onTouchEnd={() => setBtnFocus(false)}> 등록하기 </a>
                                </div>
                            </div>
                        </Sheet.Content>
                    </Sheet.Container>

                    <Sheet.Backdrop style={{ opacity: "0.1" }} onTap={() => handleBackdropClick()}/>
                </Sheet>
            </div>
        </>
    );
}
export { CouponRegister };